var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MainLayout',{scopedSlots:_vm._u([{key:"navbar-title",fn:function(){return [_vm._v(" Добавить ")]},proxy:true}])},[_c('CreateFormLayout',{scopedSlots:_vm._u([{key:"title__text",fn:function(){return [_vm._v(" Прошивка ")]},proxy:true},{key:"title-flex-right",fn:function(){return [_c('div')]},proxy:true},{key:"btns",fn:function({ handleSubmit }){return [_c('a',{staticClass:"btn btn_color_white",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Отмена ")]),_c('v-btn',{staticClass:"btn btn-green",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.createFirmware)}}},[_vm._v(" Добавить ")])]}}])},[[_c('div',[_c('FormCardTitle',{attrs:{"title":"Данные прошивки"}}),_c('FormInputBlock',[_c('ValidationSelectField',{staticClass:"validation-select-field",attrs:{"label":"Чип","items":[
              { value: 'ESP', text: 'ESP' },
              { value: 'NRF', text: 'NRF' }
            ],"rules":'required'},model:{value:(_vm.firmware.chip),callback:function ($$v) {_vm.$set(_vm.firmware, "chip", $$v)},expression:"firmware.chip"}}),_c('ValidationInputField',{attrs:{"rules":"number","label":"Версия чипа","validate-name":"версия чипа"},model:{value:(_vm.firmware.board_version),callback:function ($$v) {_vm.$set(_vm.firmware, "board_version", $$v)},expression:"firmware.board_version"}}),_c('ValidationInputField',{attrs:{"rules":"","label":"Версия прошивки","validate-name":"версия прошивки"},model:{value:(_vm.firmware.version),callback:function ($$v) {_vm.$set(_vm.firmware, "version", $$v)},expression:"firmware.version"}}),_c('ValidationSelectField',{staticClass:"validation-select-field",attrs:{"label":"Тестовая","items":[
              { value: 'true', text: 'Да' },
              { value: 'false', text: 'Нет' }
            ],"rules":'required'},model:{value:(_vm.firmware.is_test),callback:function ($$v) {_vm.$set(_vm.firmware, "is_test", $$v)},expression:"firmware.is_test"}}),_c('ValidationSelectField',{staticClass:"validation-select-field",attrs:{"rules":"required","label":"Статус","items":[
              { value: true, text: 'Активен' },
              { value: false, text: 'Не активен' }
            ]},model:{value:(_vm.firmware.isActive),callback:function ($$v) {_vm.$set(_vm.firmware, "isActive", $$v)},expression:"firmware.isActive"}})],1)],1),_c('div',[_c('FormCardTitle',[_c('p',{staticClass:"data__text"},[_vm._v("Загрузка файла")])]),_c('FormInputBlock',[_c('ValidationFileField',{attrs:{"rules":'required',"accept":".bin","label":"Загрузка файла","validate-name":"загрузка файла"},model:{value:(_vm.firmware.file),callback:function ($$v) {_vm.$set(_vm.firmware, "file", $$v)},expression:"firmware.file"}})],1)],1),_c('div',[_c('FormCardTitle',{attrs:{"title":"Комментарий"}}),_c('FormInputBlock',[_c('ValidationTextareaField',{staticClass:"grid-column_1 grid-column_span_2",attrs:{"rules":"max:500","label":"Текст комментария","validate-name":"текст комментария"},model:{value:(_vm.firmware.comment),callback:function ($$v) {_vm.$set(_vm.firmware, "comment", $$v)},expression:"firmware.comment"}})],1)],1)]],2),_c('v-overlay',{attrs:{"value":_vm.loading,"z-index":"9999"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }