<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Добавить
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Прошивка
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные прошивки    -->
        <div>
          <FormCardTitle title="Данные прошивки" />
          <FormInputBlock>
            <ValidationSelectField
              class="validation-select-field"
              label="Чип"
              :items="[
                { value: 'ESP', text: 'ESP' },
                { value: 'NRF', text: 'NRF' }
              ]"
              v-model="firmware.chip"
              :rules="'required'"
            />

            <ValidationInputField
              rules="number"
              label="Версия чипа"
              v-model="firmware.board_version"
              validate-name="версия чипа"
            ></ValidationInputField>

            <ValidationInputField
              rules=""
              label="Версия прошивки"
              v-model="firmware.version"
              validate-name="версия прошивки"
            ></ValidationInputField>

            <ValidationSelectField
              class="validation-select-field"
              label="Тестовая"
              :items="[
                { value: 'true', text: 'Да' },
                { value: 'false', text: 'Нет' }
              ]"
              v-model="firmware.is_test"
              :rules="'required'"
            />

            <ValidationSelectField
              rules="required"
              class="validation-select-field"
              label="Статус"
              :items="[
                { value: true, text: 'Активен' },
                { value: false, text: 'Не активен' }
              ]"
              v-model="firmware.isActive"
            />
          </FormInputBlock>
        </div>
        <!-- Данные прошивки    -->

        <!--    Информация о файле    -->
        <div>
          <FormCardTitle>
            <p class="data__text">Загрузка файла</p>
          </FormCardTitle>
          <FormInputBlock>
            <ValidationFileField
              :rules="'required'"
              accept=".bin"
              v-model="firmware.file"
              label="Загрузка файла"
              validate-name="загрузка файла"
            />
          </FormInputBlock>
        </div>
        <div>
          <FormCardTitle title="Комментарий" />
          <FormInputBlock>
            <ValidationTextareaField
              class=" grid-column_1 grid-column_span_2 "
              rules="max:500"
              v-model="firmware.comment"
              label="Текст комментария"
              validate-name="текст комментария"
            />
          </FormInputBlock>
        </div>
        <!--    Информация о файле    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click.prevent="handleSubmit(createFirmware)"
          class="btn btn-green"
        >
          Добавить
        </v-btn>
      </template>
    </CreateFormLayout>

    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import MainLayout from "@/components/layouts/MainLayout";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import ValidationFileField from "@/components/ValidationField/ValidationFileField";
import ValidationTextareaField from "@/components/ValidationField/ValidationTextareaField";

import { createControllerFirmwaresRequest } from "@/helpers/api/controller-firmwares";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "EditFirmwaresPage",
  components: {
    ValidationFileField,
    ValidationSelectField,
    ValidationInputField,
    MainLayout,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    ValidationTextareaField
  },
  data() {
    return {
      loading: false,
      firmware: {
        chip: null,
        version: null,
        isActive: null,
        is_test: null,
        file: null,
        comment: null,
        board_version: null
      }
    };
  },
  computed: {
    getCurrentRole: {
      get() {
        return this.$store.getters.getCurrentRole;
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("clearEditCompany");
    next();
  },
  created() {},
  methods: {
    async createFirmware() {
      let {
        chip,
        version,
        isActive,
        is_test,
        file,
        comment,
        board_version
      } = this.firmware;
      const formData = new FormData();

      formData.append("chip", chip);
      formData.append("version", version);
      formData.append("is_active", isActive);
      formData.append("is_test", is_test);
      formData.append("comment", comment);
      formData.append("board_version", board_version);
      formData.append("file", file);
      formData.append("created_by", this.$store.getters.getUserId);

      return await createControllerFirmwaresRequest({
        data: formData
      })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.add(successMessageDictionary.firmwares)
          );
        })
        .then(() => {
          this.$router.go(-1);
        });
    }
  }
};
</script>

<style scoped></style>
